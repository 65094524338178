<template>

  <div class="container-rel">
    <div class="col-12 header-rel-checkout mb-5">
      <h6 class="title-rel">{{ title }}<span class="total_diff_days">Últimos {{totalDiffDays}} dias</span></h6>
      <div class="icon"></div>
    </div>

    <slot></slot>

  </div>

</template>

<script>
export default {
  name: "Container",
  props:['totalDiffDays', 'title']
}
</script>

<style scoped>

.total_diff_days{
  margin-left: 32px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #81858E;
}

.container-rel{
  border: 1px solid #E5E5E5;
  box-sizing: border-box;
  border-radius: 0px 0px 5px 5px;
  padding: 40px;
}

.title-rel{
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 27px;
  color: #333;
}

.icon{
  width: 24px;
  height: 4px;
  background: #333;
  border-radius: 5px;
}

</style>