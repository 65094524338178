<template>
  <div style="height: 300px">
    <div class="container-loading" v-show="loading">
      <b-spinner label="Loading..."></b-spinner>
    </div>

    <p class="title-chart-funil" v-show="!loading">Funil do Checkout</p>
    <div class="container-funnel" v-show="!loading">
      <div class="funnel">
        <div class="container-data-funnel">
          <div
            class="item-funnel funnel-acessos"
            v-b-tooltip.hover
            :title="`Acessos ao checkout: ${data.access}`"
          ></div>
          <div
            class="item-funnel funnel-iniciou"
            v-b-tooltip.html
            :title="`Iniciou o pagamento: ${data.started_payment}`"
          ></div>
          <div
            class="item-funnel funnel-tentativa"
            v-b-tooltip.html
            :title="`Tentativa de pagamento: ${data.payment_attempt}`"
          ></div>
          <div
            class="item-funnel funnel-realizado end-item-funnel"
            v-b-tooltip.html
            :title="`Pagamentos realizados: ${data.payment_made}`"
          ></div>
        </div>
      </div>

      <div class="container-label-funnel" style="height: 274px">
        <label class="">
          <span class="">Acessos ao checkout</span>
          <div class="legendValue d-flex align-items-center">
            <div class="mark-label" style="background-color: #091E3E"></div>
            <span class="ml-4 value_item_rel">{{ data.access }}</span>
          </div>
        </label>
        <label class="">
          <span class="">Iniciou o pagamento</span>
          <div class="legendValue d-flex align-items-center">
            <div class="mark-label" style="background-color: #002363"></div>
            <span class="ml-4 value_item_rel">{{ data.started_payment }}</span>
          </div>
        </label>
        <label class="">
          <span class="">Tentativa de pagamento</span>
          <div class="legendValue d-flex align-items-center">
            <div class="mark-label" style="background-color: #2A63AB;"></div>
            <span class="ml-4 value_item_rel">{{ data.payment_attempt }}</span>
          </div>
        </label>
        <label class="">
          <span class="">Pagamentos realizados</span>
          <div class="legendValue d-flex align-items-center">
            <div class="mark-label" style="background-color: #90C2F1"></div>
            <span class="ml-4 value_item_rel">{{ data.payment_made }}</span>
          </div>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import ReportService from "@/services/resources/ReportService";
const Report = ReportService.build();

export default {
  name: "FunilChart",
  props: ["filter"],
  data: () => {
    return {
      loading: true,
      data: {
        access: 0,
        started_payment: 0,
        payment_attempt: 0,
        payment_made: 0,
      },
    };
  },
  watch: {
    filter: function() {
      this.loading = true;
      this.read();
    },
  },
  methods: {
    read() {
      let filter = this.filter;
      Report.search({ url: "funnel", ...filter }).then((response) => {
        this.data = Object.assign(
          {},
          {
            access: response.access,
            started_payment: response.started_payment,
            payment_attempt: response.payment_attempt,
            payment_made: response.payment_made,
          }
        );
        this.loading = false;
      });
    },
  },
};
</script>

<style scoped>
.funnel-acessos {
  background-color: #002363;
}
.funnel-iniciou {
  background: #002363;
}
.funnel-tentativa {
  background: #2a63ab;
}
.funnel-realizado {
  background: #90c2f1;
}

.mark-label {
  margin: 15px 0;
  width: 24px;
  height: 4px;
}

.container-label-funnel > label {
  margin-bottom: 25px !important;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #81858e;
}

.container-funnel {
  margin: 15px 0;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
}

@media (min-width: 600px) {
  .funnel {
    flex-grow: 0;
    max-width: 66.66667%;
    flex-basis: 66.66667%;
  }
}

.funnel {
  flex-grow: 0;
  max-width: 58.33333%;
  flex-basis: 58.33333%;
}

.container-data-funnel {
  width: 90%;
  position: relative;
}
*,
*::before,
*::after {
  box-sizing: inherit;
}

.container-data-funnel:after {
  right: 0;
  border-top: 285px solid transparent;
  border-right: 114px solid #fff;
}

.item-funnel span {
  margin: 0;
  font-size: 12px;
  font-family: Lato;
  font-weight: bold;
  letter-spacing: 0.5px;
}

.item-funnel {
  width: 100%;
  color: #fff;
  height: 68px;
  display: flex;
  flex-flow: column;
  align-items: center;
  border-bottom: 8px solid #fff;
  justify-content: center;
  border-radius: 15px;
}

.container-data-funnel:before {
  left: 0 !important;
  border-top: 285px solid transparent !important;
  border-left: 114px solid #fff !important;
}

.container-data-funnel:before,
.container-data-funnel:after {
  top: 0;
  height: 100%;
  content: "";
  display: block;
  position: absolute;
}

.title-chart-funil {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #333333;
}

.container-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  animation: preload 5s forwards linear infinite alternate;
}
</style>
