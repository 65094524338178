<template>
  <div class="row col-12 p-0">
    <div class="rel-funil col-md-6 col-12 container-chart-funil">
      <FunilChart :filter="filter" />
    </div>
    <div class="rel-barra col-md-6 col-12">
      <StackedBar
        :filter="filter"
        :loading="loading"
        :dataStackedBar="dataStackedBar"
        title="Taxa de Conversão"
        idCanvas="stackedBarCheckout"
      />
    </div>
  </div>
</template>
<script>
import FunilChart from "../../chart/FunilChart";
import StackedBar from "../../chart/StackedBar";
import ReportService from "@/services/resources/ReportService";
const Report = ReportService.build();

export default {
  name: "Checkout",
  props: ["filter"],
  data: () => {
    return {
      dataStackedBar: [],
      loading: true,
    };
  },
  components: {
    FunilChart,
    StackedBar,
  },
  watch: {
    filter: function() {
      this.loading = true;
      this.startChartStackedBar();
    },
  },
  methods: {
    startChartStackedBar() {
      Report.search({ url: "conversion-rate", ...this.filter }).then(
        (response) => {
          let labels = [];
          let dataset = [];
          let index = 0;
          for (let item in response) {
            let key = 0;
            labels.push(item);

            item = response[item];

            for (let itemMap in item) {
              itemMap = item[itemMap];

              if (index === 0) {
                dataset.push(
                  {
                    type: "bar",
                    label: "Pix",
                    backgroundColor: "#091E3E",
                    data: [itemMap.pix],
                    stack: key + 1,
                  },
                  {
                    type: "bar",
                    label: "Cartão",
                    backgroundColor: "#002363",
                    data: [itemMap.credit_card],
                    stack: key + 1,
                  },
                  {
                    type: "bar",
                    label: "Boleto",
                    backgroundColor: "#2A63AB",
                    data: [itemMap.boleto],
                    stack: key + 1,
                  },
                  {
                    type: "bar",
                    label: "Dois cartões",
                    backgroundColor: "#5592D5",
                    data: [itemMap.two_credit_cards],
                    stack: key + 1,
                  },
                  {
                    type: "bar",
                    label: "Paypal",
                    backgroundColor: "#90C2F1",
                    data: [itemMap.paypal],
                    stack: key + 1,
                  },
                  {
                    label: `Total`,
                    backgroundColor: "#C6E2F8",
                    data: [1],
                    stack: key + 1,
                  }
                );
              } else {
                let findStack = dataset.filter((data) => {
                  if (data.stack === key + 1) {
                    return data;
                  }
                });
                if (!findStack[0]) {
                  continue;
                }
                findStack[0].data.push(itemMap.pix);
                findStack[1].data.push(itemMap.credit_card);
                findStack[2].data.push(itemMap.boleto);
                findStack[3].data.push(itemMap.two_credit_cards);
                findStack[4].data.push(itemMap.paypal);
                findStack[5].data.push(1);
              }
            }
            key++;
            index++;
          }
          this.dataStackedBar = {
            labels: labels,
            datasets: dataset,
          };
          this.loading = false;
        }
      );
    },
  },
};
</script>

<style scoped>
.container-chart-funil {
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-top: 0;
  border-bottom: 0;
  border-left: 0;
  height: 300px;
}
</style>
